<template>
  <div class="first">
    <section class='site-title'>
      <div class="site-background">
        <h3>找到你想要的信息</h3>
        <h1>苦而不言，喜而不语</h1>
        <button class='btn'>开始学习之旅</button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "first",
  mounted() {
  }
}
</script>

<style scoped>
:root {
  /********主题颜色********/
  --text-gray: #354954;
  --text-light: #686666da;
  --bg-color: #0f0f0f;
  --white: #ffff;
  --midnight: #104f55;
  --sky: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
  /****x****主题颜色****x****/
  /********主题字体********/
  --Abel: 'Abel', cursive;
  --Anton: 'Anton', cursive;
  --Josefin: 'Josefin', cursive;
  --Lexend: 'Lexend', cursive;
  --Livvic: 'Livvic', cursive;
  /*****x***主题字体***x*****/
}

.first .site-title{
  background: url('../../assets/image/Background-image.png');
  background-size: cover;
  height: 110vh;
  display: flex;
  justify-content: center;
}
.first .site-title .site-background{
  padding-top: 10rem;
  text-align: center;
  color: var(--white);
}
h1{
  font-family: var(--Lexend);
  font-size: 2.5rem;
  color: #ffff;
}
h3{
  font-family: var(--Abel);
  font-size: 1.3rem;
  color: #ffff;
}
.first .site-title h1,h3{
  margin: 3rem;
}

.btn{
  border: none;
  border-radius: 2rem;
  padding: 1rem 3rem;
  font-size: 1rem;
  font-family: var(--Livvic);
  cursor: pointer;
}

.first .site-title .btn{
  margin: 1.8rem;
  background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}
.first .site-title .btn:hover{
  background: transparent;
  color: white;
  border: 1px solid white;
}
</style>
